<template>
  <iframe
    ref="iframe"
    :src="url"
    :title="`${isVimeo ? 'Vimeo' : 'YouTube'} video player`"
    allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    class="video"
    :style="style"
  />
</template>

<script lang="ts" setup>
import { uniqueId } from 'lodash-es'
import { UnwrapRef } from 'vue'

export type VideoState = 'playing' | 'paused'

const id = uniqueId('video-iframe-')

const props = withDefaults(
  defineProps<{
    videoId: string
    isVimeo?: boolean
    autoplay?: boolean
    state?: VideoState
  }>(),
  { state: 'paused' }
)

const emit = defineEmits<{
  (e: 'update:state', value: VideoState): void
  (e: 'play' | 'pause'): void
  (e: 'update:style', value: UnwrapRef<typeof style>): void
}>()

type VideoData = {
  width: number
  height: number
}

const baseUrl = computed(() =>
  props.isVimeo
    ? 'https://player.vimeo.com/video/'
    : 'https://www.youtube.com/embed/'
)

const { data: videoData } = useFetch(
  `https://noembed.com/embed?url=${baseUrl.value}${props.videoId}`,
  {
    key: props.videoId,
    transform: (data: string) => JSON.parse(data) as VideoData,
    pick: ['width', 'height']
  }
)

const style = computed(() => {
  const styles = videoData.value
    ? { aspectRatio: `${videoData.value.width}/${videoData.value.height}` }
    : { aspectRatio: '16/9' }
  emit('update:style', styles)
  return styles
})

const url = computed(() => {
  let embedUrl = `${baseUrl.value}${props.videoId}`
  embedUrl += props.isVimeo ? `?player_id=${id}` : '?enablejsapi=1'
  if (props.autoplay)
    embedUrl += props.isVimeo
      ? `&autoplay=1&loop=1&mute=1&background=1`
      : `&playlist=${props.videoId}&loop=1&autoplay=1&mute=1&controls=0`

  return embedUrl
})

// Handle video state
watch(
  () => props.state,
  value => {
    if (!value) return
    if (value === 'playing') play()
    else pause()
  }
)

const addIframeEventListeners = () => {
  let listenersSetUp = false
  const listenForAccept = (msg: MessageEvent) => {
    const vimeoAccept =
      props.isVimeo &&
      msg.origin.includes('vimeo') &&
      JSON.parse(msg.data).player_id === id
    const YTAccept =
      !props.isVimeo &&
      msg.origin.includes('youtube') &&
      JSON.parse(msg.data).id === id

    if (vimeoAccept || YTAccept) {
      listenersSetUp = true
    }
  }

  window.addEventListener('message', listenForAccept)
  if (props.isVimeo) {
    const vimeo = () => {
      if (listenersSetUp) {
        window.removeEventListener('message', listenForAccept)
        return
      }

      postMessageToIframe({ method: 'addEventListener', value: 'play' })
      postMessageToIframe({ method: 'addEventListener', value: 'pause' })

      setTimeout(vimeo, 100)
    }
    vimeo()
  } else {
    const youtube = () => {
      if (listenersSetUp) {
        window.removeEventListener('message', listenForAccept)
        return
      }

      postMessageToIframe({ id, event: 'listening' })
      postMessageToIframe({
        id,
        event: 'command',
        func: 'addEventListener',
        args: ['onStateChange']
      })

      setTimeout(youtube, 100)
    }
    youtube()
  }
}

const iframe = ref<HTMLIFrameElement>()

onMounted(() => {
  // Setup listeners for iframes
  addIframeEventListeners()

  // Add event listeners for messages from iframes
  window.addEventListener('message', msg => {
    if (props.isVimeo && msg.origin.includes('vimeo')) {
      const { event, player_id } = JSON.parse(msg.data)
      if (!event || player_id !== id) return

      emit(event === 'play' ? 'play' : 'pause')
      emit('update:state', event === 'play' ? 'playing' : 'paused')
    } else if (!props.isVimeo && msg.origin.includes('youtube')) {
      const { event, info, id: YTid } = JSON.parse(msg.data)
      if (event !== 'onStateChange' || YTid !== id) return

      emit([-1, 1, 3].includes(info) ? 'play' : 'pause')
      emit('update:state', [-1, 1, 3].includes(info) ? 'playing' : 'paused')
    }
  })
})

const postMessageToIframe = (data: Record<string, any>) => {
  if (!iframe.value) return
  const message = JSON.stringify(data)
  iframe.value.contentWindow?.postMessage(message, url.value)
}

const play = () =>
  props.isVimeo
    ? postMessageToIframe({ method: 'play' })
    : postMessageToIframe({
        id,
        event: 'command',
        func: 'playVideo'
      })

const pause = () =>
  props.isVimeo
    ? postMessageToIframe({ method: 'pause' })
    : postMessageToIframe({
        id,
        event: 'command',
        func: 'pauseVideo'
      })
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  border: 0;
}
</style>
